export const FLASH_MINT_NOTIONAL_ABI = [
  {
    inputs: [
      { internalType: 'address', name: '_weth', type: 'address' },
      {
        internalType: 'contract IController',
        name: '_setController',
        type: 'address',
      },
      {
        internalType: 'contract IWrappedfCashFactory',
        name: '_wrappedfCashFactory',
        type: 'address',
      },
      {
        internalType: 'contract INotionalTradeModule',
        name: '_notionalTradeModule',
        type: 'address',
      },
      { internalType: 'address', name: '_quickRouter', type: 'address' },
      { internalType: 'address', name: '_sushiRouter', type: 'address' },
      { internalType: 'address', name: '_uniV3Router', type: 'address' },
      { internalType: 'address', name: '_uniV3Quoter', type: 'address' },
      {
        internalType: 'address',
        name: '_curveAddressProvider',
        type: 'address',
      },
      { internalType: 'address', name: '_curveCalculator', type: 'address' },
      { internalType: 'uint256', name: '_decodedIdGasLimit', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_recipient',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: '_inputToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_amountInputToken',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_amountSetIssued',
        type: 'uint256',
      },
    ],
    name: 'FlashMint',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_recipient',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: '_outputToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_amountSetRedeemed',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_amountOutputToken',
        type: 'uint256',
      },
    ],
    name: 'FlashRedeem',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [],
    name: 'ETH_ADDRESS',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'addresses',
    outputs: [
      { internalType: 'address', name: 'quickRouter', type: 'address' },
      { internalType: 'address', name: 'sushiRouter', type: 'address' },
      { internalType: 'address', name: 'uniV3Router', type: 'address' },
      { internalType: 'address', name: 'uniV3Quoter', type: 'address' },
      {
        internalType: 'address',
        name: 'curveAddressProvider',
        type: 'address',
      },
      { internalType: 'address', name: 'curveCalculator', type: 'address' },
      { internalType: 'address', name: 'weth', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decodedIdGasLimit',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
    ],
    name: 'getFilteredComponentsIssuance',
    outputs: [
      {
        internalType: 'address[]',
        name: 'filteredComponents',
        type: 'address[]',
      },
      { internalType: 'uint256[]', name: 'filteredUnits', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
    ],
    name: 'getFilteredComponentsIssuanceAfterMaturityRedemption',
    outputs: [
      {
        internalType: 'address[]',
        name: 'filteredComponents',
        type: 'address[]',
      },
      { internalType: 'uint256[]', name: 'filteredUnits', type: 'uint256[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
    ],
    name: 'getFilteredComponentsRedemption',
    outputs: [
      {
        internalType: 'address[]',
        name: 'filteredComponents',
        type: 'address[]',
      },
      { internalType: 'uint256[]', name: 'filteredUnits', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
    ],
    name: 'getFilteredComponentsRedemptionAfterMaturityRedemption',
    outputs: [
      {
        internalType: 'address[]',
        name: 'filteredComponents',
        type: 'address[]',
      },
      { internalType: 'uint256[]', name: 'filteredUnits', type: 'uint256[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
    ],
    name: 'getRequiredIssuanceComponents',
    outputs: [
      { internalType: 'address[]', name: 'components', type: 'address[]' },
      { internalType: 'uint256[]', name: 'positions', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
    ],
    name: 'getRequiredRedemptionComponents',
    outputs: [
      { internalType: 'address[]', name: 'components', type: 'address[]' },
      { internalType: 'uint256[]', name: 'positions', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      {
        components: [
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          { internalType: 'uint24[]', name: 'fees', type: 'uint24[]' },
          { internalType: 'address', name: 'pool', type: 'address' },
          {
            internalType: 'enum DEXAdapter.Exchange',
            name: 'exchange',
            type: 'uint8',
          },
        ],
        internalType: 'struct DEXAdapter.SwapData[]',
        name: '_swapData',
        type: 'tuple[]',
      },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
      { internalType: 'bool', name: '_redeemMaturedPositions', type: 'bool' },
    ],
    name: 'issueExactSetFromETH',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'contract IERC20', name: '_inputToken', type: 'address' },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      {
        internalType: 'uint256',
        name: '_maxAmountInputToken',
        type: 'uint256',
      },
      {
        components: [
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          { internalType: 'uint24[]', name: 'fees', type: 'uint24[]' },
          { internalType: 'address', name: 'pool', type: 'address' },
          {
            internalType: 'enum DEXAdapter.Exchange',
            name: 'exchange',
            type: 'uint8',
          },
        ],
        internalType: 'struct DEXAdapter.SwapData[]',
        name: '_swapData',
        type: 'tuple[]',
      },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
      { internalType: 'bool', name: '_redeemMaturedPositions', type: 'bool' },
    ],
    name: 'issueExactSetFromToken',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'notionalTradeModule',
    outputs: [
      {
        internalType: 'contract INotionalTradeModule',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      { internalType: 'uint256', name: '_minOutputReceive', type: 'uint256' },
      {
        components: [
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          { internalType: 'uint24[]', name: 'fees', type: 'uint24[]' },
          { internalType: 'address', name: 'pool', type: 'address' },
          {
            internalType: 'enum DEXAdapter.Exchange',
            name: 'exchange',
            type: 'uint8',
          },
        ],
        internalType: 'struct DEXAdapter.SwapData[]',
        name: '_swapData',
        type: 'tuple[]',
      },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
      { internalType: 'bool', name: '_redeemMaturedPositions', type: 'bool' },
    ],
    name: 'redeemExactSetForETH',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ISetToken',
        name: '_setToken',
        type: 'address',
      },
      {
        internalType: 'contract IERC20',
        name: '_outputToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' },
      { internalType: 'uint256', name: '_minOutputReceive', type: 'uint256' },
      {
        components: [
          { internalType: 'address[]', name: 'path', type: 'address[]' },
          { internalType: 'uint24[]', name: 'fees', type: 'uint24[]' },
          { internalType: 'address', name: 'pool', type: 'address' },
          {
            internalType: 'enum DEXAdapter.Exchange',
            name: 'exchange',
            type: 'uint8',
          },
        ],
        internalType: 'struct DEXAdapter.SwapData[]',
        name: '_swapData',
        type: 'tuple[]',
      },
      { internalType: 'address', name: '_issuanceModule', type: 'address' },
      { internalType: 'bool', name: '_isDebtIssuance', type: 'bool' },
      { internalType: 'uint256', name: '_slippage', type: 'uint256' },
      { internalType: 'bool', name: '_redeemMaturedPositions', type: 'bool' },
    ],
    name: 'redeemExactSetForToken',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'setController',
    outputs: [
      { internalType: 'contract IController', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_decodedIdGasLimit', type: 'uint256' },
    ],
    name: 'updateDecodedIdGasLimit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20[]', name: '_tokens', type: 'address[]' },
      { internalType: 'address payable', name: '_to', type: 'address' },
    ],
    name: 'withdrawTokens',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wrappedfCashFactory',
    outputs: [
      {
        internalType: 'contract IWrappedfCashFactory',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
]
