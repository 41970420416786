import { Flex, Link, Text } from '@chakra-ui/react'

type ContractExecutionViewProps = {
  blockExplorerUrl: string
  contractAddress: string
  name: string
}

export const ContractExecutionView = (props: ContractExecutionViewProps) => (
  <Flex
    direction='column'
    align='center'
    justify='center'
    mt='16px'
    fontSize='11px'
  >
  </Flex>
)
