import { useICColorMode } from 'styles/colors'

import { Flex, Image, Link } from '@chakra-ui/react'
import { useColorMode } from '@chakra-ui/system'

import {
  IndexLogoBlack,
  IndexLogoFullBlack,
  IndexLogoFullWhite,
  IndexLogoWhite,
} from 'assets'

import Navigation from './Navigation'

const Header = () => {
  const { isDarkMode } = useICColorMode()
  const backgroundColor = isDarkMode
    ? '#0f0909'
    : 'rgba(252, 255, 255, 0.82)'

  return (
    <Flex
      as='header'
      backgroundColor={backgroundColor}
      backdropFilter='saturate(180%) blur(5px)'
      p={[
        '16px 16px 16px 24px',
        null,
        '32px 60px 32px 60px',
        '32px 80px 32px 80px',
      ]}
      position='fixed'
      top='0px'
      w='100vw'
      zIndex='2'
    >
      <Flex align='center' justifyContent='space-between' w='100%'>
        <Link
          href='https://magma.foundation/'
          _hover={{
            textDecoration: 'none',
          }}
          flexGrow={1}
        >
          <Flex marginRight={['', '', '', '20px']}>
            <Logo />
          </Flex>
        </Link>
        <Navigation />
      </Flex>
    </Flex>
  )
}

const Logo = () => {
  const { isDarkMode } = useICColorMode()

  if (window.innerWidth > 1350) {
    const logo = isDarkMode ? IndexLogoFullWhite : IndexLogoFullBlack
    return  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    viewBox="0 0 549 164"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g>
        <path
          fill={isDarkMode ? '#F8F6EF' : '#000'}
          d="M162.313 59.4h18.375v11.813c1.94-3.993 4.782-7.16 8.525-9.5 3.742-2.342 8.183-3.513 13.324-3.513 5.975 0 10.97 1.242 14.988 3.725 4.017 2.484 6.888 6.055 8.613 10.713 1.825-4.1 5.008-7.532 9.55-10.294 4.541-2.763 9.7-4.144 15.475-4.144 5.342 0 10.06 1.194 14.156 3.581 4.095 2.388 7.24 5.7 9.437 9.938 2.196 4.237 3.294 9.04 3.294 14.407V135h-18.375V91.638c0-5.367-1.304-9.665-3.913-12.894-2.608-3.229-6.1-4.843-10.475-4.843-4.875 0-8.752 1.614-11.631 4.843-2.88 3.23-4.319 7.527-4.319 12.894V135h-18.374V91.638c0-5.367-1.294-9.665-3.882-12.894-2.587-3.229-6.068-4.843-10.444-4.843-4.875 0-8.751 1.614-11.63 4.843-2.88 3.23-4.319 7.527-4.319 12.894V135h-18.375V59.4zM293.886 30h69.825v17.175h-50.063v25.4h40.137v17.174h-40.137v28.076h51.713V135h-71.475V30zm107.56 17.175h-31.811V30h83.325v17.175h-31.751V135h-19.763V47.175zM548.121 30v105h-19.763V30h19.763zm-86.288 105V30h19.763v105h-19.763zm17.513-45.188V72.638h51.412v17.174h-51.412z"
        ></path>
        <g>
          <path
            fill="#D04420"
            d="M61 .011c-.114 2.235-.065 4.465-.073 6.7v32.892c.01 1.51.016 3.02-.052 4.53-2.615.062-5.2.563-7.666 1.423-1.705.604-3.196 1.44-4.814 2.235-13.42 6.71-26.82 13.451-40.243 20.164-1.322.658-2.59 1.278-3.739 2.222C3.08 71.237 2.178 72.725 1 74a15.837 15.837 0 011.977-3.64C18.37 48.708 33.743 27.04 49.137 5.387 51.84 1.634 56.432-.16 61 .011z"
          ></path>
          <path
            fill="#B23018"
            d="M61.122 0c4.102.129 8.16 1.986 10.541 5.444C86.432 26.624 101.223 47.826 116 69c-.976-.544-1.896-1.169-2.845-1.759-3.148-1.672-6.338-3.257-9.523-4.849-4.087-2.158-8.224-4.195-12.329-6.305-6.343-3.265-12.65-6.476-19.006-9.728-3.447-1.764-7.293-2.55-11.143-2.493L61 43.87c.066-1.501.061-3.002.05-4.504V6.661c.008-2.222-.04-4.439.072-6.661z"
          ></path>
          <path
            fill="#86281C"
            d="M59.844 44.003L60 44c-.129 2.066-.046 4.126-.064 6.195v55.517c-.017 1.913.045 3.824-.027 5.738-.338.187-.674.374-1.017.55-3.522-.059-7.089-.875-10.234-2.46C35.46 102.92 22.25 96.28 9.059 89.662c-1.935-.93-3.757-2.024-5.28-3.55C.496 82.91-.95 77.935.665 73.57c1.165-1.263 2.056-2.736 3.374-3.785 1.135-.934 2.388-1.548 3.695-2.2C21 60.942 34.245 54.269 47.51 47.626c1.6-.788 3.073-1.615 4.759-2.213 2.437-.851 4.992-1.348 7.576-1.41z"
          ></path>
          <path
            fill="#6B291D"
            d="M60.108 44.003c3.897-.056 7.789.726 11.278 2.482 6.432 3.237 12.814 6.434 19.234 9.684 4.154 2.1 8.341 4.129 12.477 6.277 3.223 1.585 6.451 3.162 9.637 4.828.96.587 1.892 1.209 2.88 1.75 1.119 1.425 2.37 2.896 3.183 4.5.84 1.64 1.387 3.365 1.146 5.228-.18 2.575-1.302 4.907-3.151 6.706-1.659 1.713-3.693 2.533-5.776 3.6-12.347 6.242-24.73 12.401-37.084 18.638-2.313 1.129-4.578 2.413-7.007 3.178A24.61 24.61 0 0159 111.993c.344-.177.679-.363 1.017-.55.073-1.914.01-3.824.027-5.738V50.197c.019-2.068-.064-4.129.064-6.194z"
          ></path>
          <path
            fill="#D04420"
            d="M43.749 117.508c4.247 2.11 8.92 3.284 13.656 3.577.84.064 1.678.168 2.516.258.137 1.847.051 3.687.065 5.534.002 10.114 0 20.229 0 30.343-.009 1.904.04 3.804-.03 5.705-1.05.045-2.08.114-3.13.047-2.18-.423-4.253-1.293-5.958-2.72-1.15-.955-1.98-2.084-2.936-3.213-6.783-8.038-13.528-16.1-20.302-24.139-1.123-1.336-2.03-2.781-2.413-4.498-.553-2.337-.025-4.802 1.303-6.793 1.17-1.863 2.893-3.234 4.854-4.224 3.828-1.906 8.582-1.826 12.375.123z"
          ></path>
          <path
            fill="#B23018"
            d="M83.676 116.116c3.453.514 6.67 2.42 8.674 5.323 1.04 1.602 1.715 3.398 1.645 5.338.022 2.341-.98 4.395-2.414 6.173-6.857 8.312-13.697 16.649-20.546 24.97-1.018 1.274-2.125 2.584-3.424 3.574-3.006 2.175-6.995 2.966-10.611 2.248 1.045.068 2.068-.002 3.113-.048.07-1.932.021-3.864.03-5.799 0-10.282.002-20.565 0-30.847-.014-1.878.072-3.748-.065-5.626-.833-.092-1.666-.197-2.502-.262 5.082.276 10.182-.487 14.924-2.351 2.097-.834 3.847-1.954 6.076-2.482 1.655-.39 3.415-.392 5.1-.21z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  }

  const logo = isDarkMode ? IndexLogoWhite : IndexLogoBlack
  return  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={200}
  viewBox="0 0 549 164"
>
  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
    <g>
      <path
        fill="#F8F6EF"
        d="M162.313 59.4h18.375v11.813c1.94-3.993 4.782-7.16 8.525-9.5 3.742-2.342 8.183-3.513 13.324-3.513 5.975 0 10.97 1.242 14.988 3.725 4.017 2.484 6.888 6.055 8.613 10.713 1.825-4.1 5.008-7.532 9.55-10.294 4.541-2.763 9.7-4.144 15.475-4.144 5.342 0 10.06 1.194 14.156 3.581 4.095 2.388 7.24 5.7 9.437 9.938 2.196 4.237 3.294 9.04 3.294 14.407V135h-18.375V91.638c0-5.367-1.304-9.665-3.913-12.894-2.608-3.229-6.1-4.843-10.475-4.843-4.875 0-8.752 1.614-11.631 4.843-2.88 3.23-4.319 7.527-4.319 12.894V135h-18.374V91.638c0-5.367-1.294-9.665-3.882-12.894-2.587-3.229-6.068-4.843-10.444-4.843-4.875 0-8.751 1.614-11.63 4.843-2.88 3.23-4.319 7.527-4.319 12.894V135h-18.375V59.4zM293.886 30h69.825v17.175h-50.063v25.4h40.137v17.174h-40.137v28.076h51.713V135h-71.475V30zm107.56 17.175h-31.811V30h83.325v17.175h-31.751V135h-19.763V47.175zM548.121 30v105h-19.763V30h19.763zm-86.288 105V30h19.763v105h-19.763zm17.513-45.188V72.638h51.412v17.174h-51.412z"
      ></path>
      <g>
        <path
          fill="#D04420"
          d="M61 .011c-.114 2.235-.065 4.465-.073 6.7v32.892c.01 1.51.016 3.02-.052 4.53-2.615.062-5.2.563-7.666 1.423-1.705.604-3.196 1.44-4.814 2.235-13.42 6.71-26.82 13.451-40.243 20.164-1.322.658-2.59 1.278-3.739 2.222C3.08 71.237 2.178 72.725 1 74a15.837 15.837 0 011.977-3.64C18.37 48.708 33.743 27.04 49.137 5.387 51.84 1.634 56.432-.16 61 .011z"
        ></path>
        <path
          fill="#B23018"
          d="M61.122 0c4.102.129 8.16 1.986 10.541 5.444C86.432 26.624 101.223 47.826 116 69c-.976-.544-1.896-1.169-2.845-1.759-3.148-1.672-6.338-3.257-9.523-4.849-4.087-2.158-8.224-4.195-12.329-6.305-6.343-3.265-12.65-6.476-19.006-9.728-3.447-1.764-7.293-2.55-11.143-2.493L61 43.87c.066-1.501.061-3.002.05-4.504V6.661c.008-2.222-.04-4.439.072-6.661z"
        ></path>
        <path
          fill="#86281C"
          d="M59.844 44.003L60 44c-.129 2.066-.046 4.126-.064 6.195v55.517c-.017 1.913.045 3.824-.027 5.738-.338.187-.674.374-1.017.55-3.522-.059-7.089-.875-10.234-2.46C35.46 102.92 22.25 96.28 9.059 89.662c-1.935-.93-3.757-2.024-5.28-3.55C.496 82.91-.95 77.935.665 73.57c1.165-1.263 2.056-2.736 3.374-3.785 1.135-.934 2.388-1.548 3.695-2.2C21 60.942 34.245 54.269 47.51 47.626c1.6-.788 3.073-1.615 4.759-2.213 2.437-.851 4.992-1.348 7.576-1.41z"
        ></path>
        <path
          fill="#6B291D"
          d="M60.108 44.003c3.897-.056 7.789.726 11.278 2.482 6.432 3.237 12.814 6.434 19.234 9.684 4.154 2.1 8.341 4.129 12.477 6.277 3.223 1.585 6.451 3.162 9.637 4.828.96.587 1.892 1.209 2.88 1.75 1.119 1.425 2.37 2.896 3.183 4.5.84 1.64 1.387 3.365 1.146 5.228-.18 2.575-1.302 4.907-3.151 6.706-1.659 1.713-3.693 2.533-5.776 3.6-12.347 6.242-24.73 12.401-37.084 18.638-2.313 1.129-4.578 2.413-7.007 3.178A24.61 24.61 0 0159 111.993c.344-.177.679-.363 1.017-.55.073-1.914.01-3.824.027-5.738V50.197c.019-2.068-.064-4.129.064-6.194z"
        ></path>
        <path
          fill="#D04420"
          d="M43.749 117.508c4.247 2.11 8.92 3.284 13.656 3.577.84.064 1.678.168 2.516.258.137 1.847.051 3.687.065 5.534.002 10.114 0 20.229 0 30.343-.009 1.904.04 3.804-.03 5.705-1.05.045-2.08.114-3.13.047-2.18-.423-4.253-1.293-5.958-2.72-1.15-.955-1.98-2.084-2.936-3.213-6.783-8.038-13.528-16.1-20.302-24.139-1.123-1.336-2.03-2.781-2.413-4.498-.553-2.337-.025-4.802 1.303-6.793 1.17-1.863 2.893-3.234 4.854-4.224 3.828-1.906 8.582-1.826 12.375.123z"
        ></path>
        <path
          fill="#B23018"
          d="M83.676 116.116c3.453.514 6.67 2.42 8.674 5.323 1.04 1.602 1.715 3.398 1.645 5.338.022 2.341-.98 4.395-2.414 6.173-6.857 8.312-13.697 16.649-20.546 24.97-1.018 1.274-2.125 2.584-3.424 3.574-3.006 2.175-6.995 2.966-10.611 2.248 1.045.068 2.068-.002 3.113-.048.07-1.932.021-3.864.03-5.799 0-10.282.002-20.565 0-30.847-.014-1.878.072-3.748-.065-5.626-.833-.092-1.666-.197-2.502-.262 5.082.276 10.182-.487 14.924-2.351 2.097-.834 3.847-1.954 6.076-2.482 1.655-.39 3.415-.392 5.1-.21z"
        ></path>
      </g>
    </g>
  </g>
</svg>
}

export default Header
